import BaseComponent from "lib@/base_component";
import { parseJsonToObject, getObjProperty } from "src@/libs/util";

class ProjectSettingCpn extends BaseComponent
{
    constructor(cfg = null){
        super();
        const defaultCfg = {
            settingData: '',
            feature_bit:0,
        }
        this._cfg = {
            ...defaultCfg,
            ...cfg,
        };
        this._settingData = null;
        this._feature_bit = null;
        this.init();
    }

    init(){
        if(this._cfg.settingData != null && this._cfg.settingData != '' && typeof this._cfg.settingData != "undefined"){
            this._settingData = parseJsonToObject(this._cfg.settingData);
        }
        this._feature_bit = parseJsonToObject(this._cfg.feature_bit);
        return true;
    }

    /**
     * 获取项目的处方训练模式
     * @returns {number}
     */
    getPrescriptionTrainModel(){
        return getObjProperty(this._settingData, 'prescription.train_model', 3);
    }

    /**
     * 判断当前项目的处方类型是否为 气道廓清
     * @returns {boolean}
     */
     isPrescriptionRct(){
        if(getObjProperty(this._settingData, 'prescription.train_model', 3) == 10){
            return true;
        }
        else{
            return false;
        }
    }

    /**
     * 判断当前项目的处方类型是否为 生科
     * @returns {boolean}
     */
    isPrescriptionShengke(){
        if( (this._feature_bit & 4)  > 0){
            return true;
        }
        else{
            return false;
        }
    }


    /**
     * 判断当前项目是否为 气道廓清
     * @returns {boolean}
     */
     isProjectRct(){
        if( (this._feature_bit & 2)  > 0){
            return true;
        }
        else{
            return false;
        }
    }
    /**
     * 判断当前项目的处方类型是否为 吸气训练
     * @returns {boolean}
     */
    isPrescriptionInhale(){
        if(getObjProperty(this._settingData, 'prescription.train_model', 3) == 3){
            return true;
        }
        else{
            return false;
        }
    }
    
    /**
     * 判断当前项目是否是ecrf项目
     * @returns {boolean}
     */
     isProjectEcrf(){
        if( (this._feature_bit & 1)  > 0){
            return true;
        }
        else{
            return false;
        }
    }

    /**
     * 判断当前项目是否 仅为 ecrf项目
     * @returns {boolean}
     */
    isOnlyProjectEcrf(){
        if( this._feature_bit ==1){
            return true;
        }
        else{
            return false;
        }
    }
}

export default ProjectSettingCpn;