/**
 * 工具函数
 */

/**
 * 打印日志的函数 正式环境下不打印
 * @param {any} data
 */
export function log(data) {
    if(process.env.NODE_ENV != 'production'){
        console.log(data);
    }
}

/**
 * 判断参数是否为空对象的方法
 * @param {Object} obj
 * @returns boolean
 */
export function isEmptyObj(obj){
    return (obj && Object.keys(obj).length > 0) ? false : true;
}

/**
 * 判断嵌套的属性，是否存在的函数
 * @param {object} obj          待判断的对象
 * @param {string} propertyPath 嵌套的属性链
 * @returns boolean
 */
export function checkObjProperty (obj, propertyPath) {
    if(!obj || obj == 'undefined' || obj == undefined){
        return false;
    }
    let arr = propertyPath.split(".");
    let tempObj = obj;
    for(let i = 0; i < arr.length; i++)
    {
        if(tempObj[arr[i]] == undefined){
            return false;
        }else{
            tempObj = tempObj[arr[i]];
        }
    }

    return true;
}

/**
 * 获取对象属性的值的函数
 * @param {object} obj          待判断的对象
 * @param {string} propertyPath 嵌套的属性链
 * @param {any}    defaultVal   属性不存在时的返回值
 * @returns any
 */
export function getObjProperty(obj, propertyPath, defaultVal = undefined) {
    if(checkObjProperty(obj, propertyPath)){
        let arr = propertyPath.split(".");
        let tempObj = obj;
        for(let i = 0; i < arr.length; i++){
            tempObj = tempObj[arr[i]];
        }
        return tempObj;
    }
    else{
        return defaultVal;
    }
}

/**
 * 判断变量是否为空值的方法
 * @param {any} val
 * @returns boolean
 */
export function hasValue(val) {
    return (val != null && val != undefined && val != '') ? true : false;
}

/**
 * 将对象中，不为空值的属性，进行 url encoe 编码的函数
 * @param {object} obj
 * @returns {string}
 */
export function urlEncode(obj) {
    let params = [];

    for(let k in obj){
        if(hasValue(obj[k])){
            params.push(`${k}=${encodeURIComponent(obj[k])}`);
        }
    }

    return params.join('&');
}

/**
 * 获取原始dom的
 * @param {string}} id
 * @returns dom
 */
export function getDom(id) {
    return document.getElementById(id);
}

/**
 * 对原始值进行指定位数的四舍五入的函数
 * @param {number} val 原始值
 * @param {integer} bit 希望保留的小数位数
 * @returns 相应精度的值
 */
export function myRound(val, bit){
    let t = Math.pow(10, bit);
    return Math.round(val * t) / t;
}

/**
 * 对原始值进行指定保留位数的函数 不进行四舍五入
 * @param {number} val 原始值
 * @param {integer} bit 希望保留的小数位数
 * @returns 相应精度的值
 */
export function myParseInt(val, bit){
    let t = Math.pow(10, bit);
    return parseInt(val * t) / t;
}

/**
 * 监听窗口大小变化的事件，并设置取消监听的条件
 * @param {function} callbackFun 回调函数
 * @param {function} offFun      取消监听的条件函数 返回true时，取消监听
 * @returns void
 */
export function windowResizeAndOff(callbackFun, offFun) {
    let tmpFun = () => {
        if(offFun() != true){
            callbackFun();
        }
        else{
            $(window).off('resize', tmpFun);
        }
    };

    $(window).on('resize', tmpFun);
}

/**
 * 如果 原值为空 输出默认值的函数
 * @param {any} val 原值
 * @param {any} def 默认值
 * @returns any
 */
export function withDefault(val, def){
    return val || def;
}


/**
 * 计算日期之前的天数差
 * @since zhangjh
 * sDate1 和 sDate2 是2006-12-18格式
 * @param  {string} sDate1 日期
 * @param  {string} sDate2 日期
 * @return {integer}       多少天
 */
export function getDateDiffDays(sDate1 , sDate2){
    // 将日期字符串分隔为数组,数组元素分别为年.月.日
    var date1Str = sDate1.split("-");
    // 根据年.月.日的值创建Date对象
    var date1Obj = new Date(date1Str[0], (date1Str[1]-1), date1Str[2]);
    // 日期2同上
    var date2Str = sDate2.split("-");
    var date2Obj = new Date(date2Str[0], (date2Str[1]-1), date2Str[2]);
    var t1 = date1Obj.getTime();
    var t2 = date2Obj.getTime();
    // 每一天的毫秒数
    var dateTime = 1000*60*60*24;
    // 计算出两个日期的天数差
    var minusDays = Math.floor(((t2-t1) / dateTime));
    // 取最终绝对值
    var days = Math.abs(minusDays);
    return days;
}

/**
 * 时间格式转换
 * now 时间格式的字符串  mask 要转换后的格式  yy/M/d、yyyy-MM-dd 等
 */
export function Format(now, mask) {
    var d =new Date(now);
    var zeroize = function (value, length) {
        if (!length) length = 2;
        value = String(value);
        for (var i = 0, zeros = ''; i < (length - value.length) ; i++) {
            zeros += '0';
        }
        return zeros + value;
    };

    return mask.replace(/"[^"]*"|'[^']*'|\b(?:d{1,4}|m{1,4}|yy(?:yy)?|([hHMstT])\1?|[lLZ])\b/g, function ($0) {
        switch ($0) {
            case 'd': return d.getDate();
            case 'dd': return zeroize(d.getDate());
            case 'ddd': return ['Sun', 'Mon', 'Tue', 'Wed', 'Thr', 'Fri', 'Sat'][d.getDay()];
            case 'dddd': return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][d.getDay()];
            case 'M': return d.getMonth() + 1;
            case 'MM': return zeroize(d.getMonth() + 1);
            case 'MMM': return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][d.getMonth()];
            case 'MMMM': return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][d.getMonth()];
            case 'yy': return String(d.getFullYear()).substr(2);
            case 'yyyy': return d.getFullYear();
            case 'h': return d.getHours() % 12 || 12;
            case 'hh': return zeroize(d.getHours() % 12 || 12);
            case 'H': return d.getHours();
            case 'HH': return zeroize(d.getHours());
            case 'm': return d.getMinutes();
            case 'mm': return zeroize(d.getMinutes());
            case 's': return d.getSeconds();
            case 'ss': return zeroize(d.getSeconds());
            case 'l': return zeroize(d.getMilliseconds(), 3);
            case 'L': var m = d.getMilliseconds();
                if (m > 99) m = Math.round(m / 10);
                return zeroize(m);
            case 'tt': return d.getHours() < 12 ? 'am' : 'pm';
            case 'TT': return d.getHours() < 12 ? 'AM' : 'PM';
            case 'Z': return d.toUTCString().match(/[A-Z]+$/);
                // Return quoted strings with the surrounding quotes removed
            default: return $0.substr(1, $0.length - 2);
        }
    });
};

/**
 * 判断是否在框架内的函数
 * @returns bool
 */
export function isInFrame(){
    return window.top == window.self ? false : true;
}

/**
 * 等待某个条件成立的函数
 * @param {function} checkFunc 
 * @param {integer} timeGap 
 * @returns Promise
 */
export function afterFor(checkFunc, timeGap = 300){
    return new Promise((resolve, reject) => {
        let func = () => {
            if(checkFunc() == true){
                resolve();
            }
            else{
                setTimeout(func, timeGap);
            }
        }
        func();
    });
}

/**
 * 给旧的url添加填写新的参数, 可以旧的查询参数进行更新, 对设置的空值进行删除
 * @param originalUrl 原始的url链接
 * @param obj 新的参数
 */
export function addNewParamsToURL(originalUrl, obj) {
    if (!hasValue(originalUrl)) return '';
    var originalUrlParams = originalUrl.split("?");  //分离
    var urlOriginStr = originalUrlParams[0];
    var urlSearchParamsStr = originalUrlParams[1]?originalUrlParams[1]:'';
    var urlParams = new URLSearchParams(urlSearchParamsStr);
    // 添加新的参数
    for(let k in obj) {
        if(hasValue(obj[k])) {
            urlParams.set(k, obj[k]);
        }else {
            urlParams.delete(k);
        }
    }
    if (urlParams.size < 1) return urlOriginStr;
    return urlOriginStr+"?"+urlParams.toString();
}

/**
 * 解析json字符串的函数
 * @param {string} str
 * @returns {object}
 */
export function parseJsonToObject(str) {
    try {
        return JSON.parse(str);
    } catch (e) {
        console.error('json parse error: ', e, ' str: ', str);
        return {};
    }
}

/**
 * 
 * @param {string} tdSelector 表格列的选择器字符串
 * @param {string} appendMergeKey 用来判断单元格内容是否相同的附加数据属性的 key名
 */
export function tableCellMerge(tdSelector, appendMergeKey = ''){
    let _w_table_firsttd   = "";
    let _w_table_currenttd = "";
    let _w_table_SpanNum   = 0;
    let _w_table_Obj       = $(tdSelector);
    let _is_same           = 0;

    _w_table_Obj.each(function(i){
        if(i == 0){
            _w_table_firsttd = $(this);
            _w_table_SpanNum = 1;
        }else{
            _is_same           = 0;
            _w_table_currenttd = $(this);

            if(_w_table_firsttd.html() == _w_table_currenttd.html()){
                if(appendMergeKey == '' || (_w_table_firsttd.attr(appendMergeKey) == _w_table_currenttd.attr(appendMergeKey))){
                    _w_table_SpanNum++;
                    _w_table_currenttd.hide(); //remove();
                    _w_table_firsttd.attr("rowSpan",_w_table_SpanNum);
                    _is_same = 1;
                }
            }

            if(_is_same == 0){
                _w_table_firsttd = $(this);
                _w_table_SpanNum = 1;
            }
        }
    });
}

/**
 * 将秒数转换为文字的函数
 * @param {integer} second 秒数
 * @returns string
 */
export function secondToWord(second) {
    if (second <= 120) {
        return `${Math.max(second, 0)}秒`;
    } else if (second <= 3600) {
        return `${Math.ceil(second / 60)}分钟`;
    } else {
        let m = second % 3600;
        let h = Math.floor(second / 3600);
        if (m == 0) {
            return `${h}小时`;
        }
        else if(m > 60){
            return `${h}小时${Math.floor(m / 60)}分钟`;
        }
        else{
            return `${h}小时1分钟`;
        }
    }
}