import {log, isInFrame, afterFor} from 'lib@/util';
import cfg from 'lib@/cfg';
import User from 'lib@/user';
import ProjectSettingCpn from "src@/components/project_setting_cpn";
import myprojectApi from 'bkn@/myproject_api';


/**
 * 全局基础操作
 */
class App
{
    constructor() {
        this._req         = null;
        this._res         = null;
        this._next        = null;
        this._router      = null;
        this._shownLayout = false;

        /**
         * 项目设置的解析对象
         */
        this._projectSettingObj = null;

        this._loading = false;
    }

    get req() {
        return this._req;
    }

    get res() {
        return this._res;
    }

    get next() {
        return this._next;
    }

    get router() {
        return this._router;
    }

    set req(req) {
        this._req = req;
    }

    set res(res) {
        this._res = res;
    }

    set next(next) {
        this._next = next;
    }

    set router(router) {
        this._router = router;
    }

    /**
     * 获取模版渲染函数的方法
     * @param {string} tplName
     * @returns promise
     */
    async getTpl(tplName) {
        try{
            let {default: tpl} = await import(`tpl@/${tplName}.art`);
            return tpl;
        }
        catch(error){
            log(error);
        }
        return () => {};
    }

    /**
     * 载入页面对象的方法
     * @param {string} pageName 
     * @returns promise
     */
    async getPage(pageName) {
        try{
            log(pageName);
            pageName = pageName.replace(/\-/g, '_');
            if(pageName.charAt(0) == '/'){
                pageName = pageName.substr(1);
            }
            let {default: page} = await import(`page@/${pageName}.js`);
            return page;
        }
        catch(error){
            log(error);
            let {default: page} = await import(`page@/error404.js`);
            return page;
        }
    }

    /**
     * 输出默认布局界面的方法
     * @returns Promise
     */
    outputLayout(tplData, lastPage = null){
        return new Promise(async (resolve, reject) => {
            // 未在iframe内
            if(window.top == window.self){
                let {default: LayoutFrameCpn} = await import(`src@/components/layout_frame_cpn`);

                if(lastPage){
                    lastPage.leave();
                }

                new LayoutFrameCpn({
                    App: this,
                    ...tplData
                });
            }
            // 已在iframe内
            else{
                resolve();
            }
        });
    }

    /**
     * 在默认布局的主区域内输出 html 的方法
     * @param {string} html 
     */
    render(html){
        $("#main_content").html(html);
    }

    /**
     * 在整个页面内输出 html 的方法
     * @param {string} html 
     */
    renderFully(html){
        $("#main_page").html(html);
    }

    /**
     * 附加分页参数的方法
     * @param {number} page 
     * @param {number} perPage 
     * @param {object} other 
     * @returns object
     */
    getReqPaginator( other = null,page = 1, perPage = 10){
        return {
            page: page,
            per_page: perPage,
            ...other,
        };
    }

    /**
     * 获取 符合分页返回结构的 数据实体
     * @param {object} resData 
     * @returns object
     */
    getResPaginator(resData){
        return {
            total       : resData.total || 0,
            per_page    : resData.per_page || 10,
            current_page: resData.current_page || 1,
            last_page   : resData.last_page || 1,
            data        : resData.data || [],
        };
    }

    setDocumentTitle(title){
        title = title + ' - ' + cfg.project_name;
        if(isInFrame()){
            window.top.document.title = title;
        }
        else{
            document.title = title;
        }
    }

    revertDocumentTitle(){
        if(isInFrame()){
            window.top.document.title = cfg.project_name;
        }
        else{
            document.title = cfg.project_name;
        }
    }

    /**
     * 按项目动态创建组件的方法
     * @param {function} cpnNameFunc 获取组件路径的回调函数 cpnNameFunc(projectSettingObj): string
     * @returns Promise
     */
    async getExtCpn(cpnNameFunc, ...args){
        if(this._loading == true){
            await afterFor(
                () => {
                    return this._loading == false;
                },
                100
            );
        }
        if(this._projectSettingObj == null){
            this._loading = true;
            let projectId = User.getUserData('project_id');
            let res = await myprojectApi.getProjectDetail({
                project_id: projectId,
                for_doctor: 1,
            });

            this._projectSettingObj = new ProjectSettingCpn({
                settingData: res.payload.project.setting_data,
                feature_bit: res.payload.project.feature_bit,
            });
            this._loading = false;
        }

        let cpnName = cpnNameFunc(this._projectSettingObj);

        if(!cpnName){
            return null;
        }

        let extCpnClass = await import(`cpn@/ext/${cpnName}.js`);
        let cpn = new extCpnClass.default(...args);
        return cpn;
    }
}

export default new App();