/**
 * 组件类的基础类
 */

 class BaseComponent
 {
     constructor() {

     }

     /**
      * 对实例方法对调用代理
      * @param {string} methodName 
      * @param  {...any} args 
      */
     callProxy(methodName, ...args){
     }
 }

 export default BaseComponent;