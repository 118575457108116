import BaseApi from "lib@/base_api"
import HttpClient from "lib@/http_client"

class MyprojectApi extends BaseApi
{
    constructor(){
        super();
    }
    // 患者批量入组
    async contactJoinProjectGroup(reqData){
        try{
            return await HttpClient.post(
                'partner/s2copd/contactjoinprojectgroup',
                reqData
            );
        }
        catch(error){
            super.exceptionHandle(error);
            return false;
        }
    }
    // 获取可入组患者
    async getProjectAddContact(reqData, beforeResponseCallback = null){
        return await super.callApiAutoStop(
            HttpClient.get(
                '/partner/s2copd/getprojectaddcontact',
                reqData
            ),
            beforeResponseCallback
        );
    }

    //获取柱状图
    async getCompleteDataBar(reqData, beforeResponseCallback = null){
        return await super.callApiAutoStop(
            HttpClient.get(
                '/partner/s2copd/getcompletedata',
                reqData
            ),
            beforeResponseCallback
        );
    }

    //获取统计
    async getProjectDoctorCount(reqData, beforeResponseCallback = null){
        return await super.callApiAutoStop(
            HttpClient.get(
                '/partner/s2copdv2/project-manage/project-count',
                reqData
            ),
            beforeResponseCallback
        );
    }

    //获取顾问列表
    async getProjectDoctorList(reqData, beforeResponseCallback = null){
        return await super.callApiAutoStop(
            HttpClient.get(
                '/partner/s2copdv2/hospital/second-doctor-list',
                reqData
            ),
            beforeResponseCallback
        );
    }

    //获取机构
    async getProjectDetail(reqData, beforeResponseCallback = null){
        return await super.callApiAutoStop(
            HttpClient.get(
                '/partner/s2copdv2/project-manage/detail',
                reqData
            ),
            beforeResponseCallback
        );
    }
    //获取患者
    async getProjectUsersList(reqData, beforeResponseCallback = null){
        return await super.callApiAutoStop(
            HttpClient.get(
                '/partner/s2copdv2/project-manage/project-user-list',
                reqData
            ),
            beforeResponseCallback
        );
    }

    async getMyprojectList(reqData, beforeResponseCallback = null){
        return await super.callApiAutoStop(
            HttpClient.get(
                '/partner/s2copd/getmyprojectlist',
                reqData
            ),
            beforeResponseCallback
        );
    }

    async setGroup(reqData){
        try{
            return await HttpClient.post(
                '/partner/s2copd/group/setGroup',
                reqData
            );
        }
        catch(error){
            super.exceptionHandle(error);
            return false;
        }
    }

    /**
     * 修改项目患者的自动配置处方参数的接口
     * @param {object} reqData 
     * @param {function} beforeResponseCallback 
     * @returns 
     */
    async putSettingType(reqData, beforeResponseCallback = null){
        return await super.callApiAutoStop(
            HttpClient.put(
                '/partner/s2copdv2/patient-manage/setting-type',
                reqData
            ),
            beforeResponseCallback
        );
    }

    /**
     * 获取项目患者信息的接口
     * @param {object} reqData 
     * @param {function} beforeResponseCallback 
     * @returns 
     */
    async getProjectPatient(reqData, beforeResponseCallback = null){
        return await super.callApiAutoStop(
            HttpClient.get(
                '/partner/s2copdv2/patient-manage/project-patient',
                reqData
            ),
            beforeResponseCallback
        );
    }
    /**
     * 首页每日处理时间弹窗
     * @param {object} reqData 
     * @param {function} beforeResponseCallback 
     * @returns 
     */
    async getIndexDealData(reqData, beforeResponseCallback = null){
        return await super.callApiAutoStop(
            HttpClient.get(
                '/partner/ecrf/assessment-data/deal',
                reqData
            ),
            beforeResponseCallback
        );
    }

    async smsStatusSave(reqData){
        try{
            return await HttpClient.post(
                'partner/s2copd/sms-status-save',
                reqData
            );
        }
        catch(error){
            super.exceptionHandle(error);
            return false;
        }
    }

    async smsStatus(reqData){
        try{
            return await HttpClient.get(
                'partner/s2copd/sms-status',
                reqData
            );
        }
        catch(error){
            super.exceptionHandle(error);
            return false;
        }
    }

    //获取患者的症状评估、最后一次训练报告、最后一次测试报告
    async getPatientReportSituation(reqData, beforeResponseCallback = null){
        return await super.callApiAutoStop(
            HttpClient.get(
                '/partner/s2copdv2/patient-manage/patient-report',
                reqData
            ),
            beforeResponseCallback
        );
    }

    //获取操作日志
    async getLogList(reqData, beforeResponseCallback = null){
        return await super.callApiAutoStop(
            HttpClient.get(
                '/partner/s2copd/log',
                reqData
            ),
            beforeResponseCallback
        );
    }

}

export default new MyprojectApi();