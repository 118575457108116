/**
 * 需要登录的错误
 */
class NeedLoginError extends Error {
    constructor(message) {
        super(message);
        this.name = "NeedLoginError";
        this.message = message;
    }
}

export default NeedLoginError;