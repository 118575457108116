import NeedLoginError from "lib@/errors/need_login_error";
import ShowMsgError from "lib@/errors/show_msg_error";
import {log} from "lib@/util";
import App from "lib@/app";

/**
 * 后端接口的基础类
 */
 class BaseApi
 {
    constructor() {

    }

    callApiAutoStop(requestPromise, beforeResponseCallback = null){
        return new Promise((resolve, reject) => {
            requestPromise.then((res) => {
                if(beforeResponseCallback){
                    beforeResponseCallback(true, res);
                }
                resolve(res);
            })
            .catch((error) => {
                let next = true;

                if(beforeResponseCallback){
                    next = beforeResponseCallback(false, error);
                }

                if(next !== false){
                    this.exceptionHandle(error);
                }
            })
        });
    }

    exceptionHandle(error) {
        log(error);
        if(error instanceof NeedLoginError){
            App.router.go('/login');
        }
        else if(error instanceof ShowMsgError){
            layui.layer.msg(
                error.message,
                {
                    icon: 5
                    ,shade:0.1
                }
            );
        }
    }
 }

 export default BaseApi;