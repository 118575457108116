/**
 * 显示提示的错误
 */
 class ShowMsgError extends Error {
    constructor(message) {
        super(message);
        this.name = "ShowMsgError";
        this.message = message;
    }
}

export default ShowMsgError;