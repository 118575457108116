/**
 * 配置数据获取类
 */
//读取env配置文件 加载地址
import env from "../../env";
let cfg = {
    token_header_key: 'Authorization',   // http header 中存 token 的头
    // project_name    : '呼吸慢病科研平台',   // 项目的中文标题
    project_name    : 'eCRF病例管理科研平台',   // 项目的中文标题
    build_no        : 1,                 // 构建号
    ...env,
};
export default new Proxy(cfg, {
    get: (target, propKey) => {
        if(propKey in target){
            return target[propKey];
        }
        else{
            throw new ReferenceError('property '+ propKey +' dose not exists');
        }
    }
});